const ChartIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.28393 6.31358H2.55761C3.61128 6.31358 4.47337 7.17567 4.47337 8.22934V17.8082C4.47337 18.8618 3.61128 19.7239 2.55761 19.7239H2.28393C1.23026 19.7239 0.368164 18.8618 0.368164 17.8082V8.22934C0.368164 7.17567 1.23026 6.31358 2.28393 6.31358V6.31358ZM9.94698 0.566284C11.0007 0.566284 11.8627 1.42838 11.8627 2.48205V17.8082C11.8627 18.8618 11.0007 19.7239 9.94698 19.7239C8.89331 19.7239 8.03122 18.8618 8.03122 17.8082V2.48205C8.03122 1.42838 8.89331 0.566284 9.94698 0.566284ZM17.61 11.5135C18.6637 11.5135 19.5258 12.3756 19.5258 13.4293V17.8082C19.5258 18.8618 18.6637 19.7239 17.61 19.7239C16.5564 19.7239 15.6943 18.8618 15.6943 17.8082V13.4293C15.6943 12.3756 16.5564 11.5135 17.61 11.5135V11.5135Z"
        fill="#4318FF"
      />
    </svg>
  );
};

export default ChartIcon;
